.call-ribbon {
  @apply fixed left-0 top-0 mt-40 bg-brand-a text-white pr-12 pt-1 pb-2 pl-2 shadow-xl z-10 leading-tight;
  background-image: url(~@/assets/svg/icons/phone-w.svg);
  background-size: 32px 30px;
  background-repeat: no-repeat;
  background-position: 97% 50%;

  transition: all 0.3s ease-in-out;
  transform: translate(-100%, 0) translate(48px);
  /*transform: translate(calc(-100% + 48px), 0);*/

  &:hover {
    transform: translate(0, 0) !important;
  }
}

html.loaded .call-ribbon {
  opacity: 1 !important;
}

@screen below-md {
  .call-ribbon {
    @apply mt-20;
  }
/*  .call-ribbon {
    @apply mt-12 left-auto right-0 pr-2 pl-12;
    background-position: 4% 50%;
    transform: translate(100%, 0) translate(-48px);
  }
*/

  .menu .call-ribbon {
    transform: translate(-100%, 0);
  }

  .mobile-nav .call-ribbon {
    @apply relative w-full mt-0 pl-2 left-auto right-auto;
    background-position: 97% 50%;
    transform: none;
  }
}
