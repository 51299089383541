/*
* @Author: Franz Thüs
* @Date:   2019-12-02 11:23:42
* @Last Modified by:   Franz Thüs
* @Last Modified time: 2019-12-02 11:24:18
*/

.footer {
  @apply border-b-6 border-brand-a relative;
}

.footer:after {
  @apply bg-brand-a absolute block w-full;
  content: '';
  height: 2px;
  bottom: 8px;
}
