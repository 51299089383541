.input,
.flatpickr-input {
  @apply appearance-none;
}

.radio,
.checkbox {
  user-select: none;
  cursor: pointer;

  & input {
    display: none;
  }

  & label {
    @apply flex flex-row;
    cursor: pointer;
    & a {
      border-bottom: 1px dotted currentColor;
      &:hover {
        border-bottom: 1px solid currentColor;
      }
    }
  }

  & label:before {
    @apply block flex-none cursor-pointer;
    content: '';
  }

  & label span {
    @apply block relative;
    top: -1px;
  }
}

.flatpickr-wrapper,
.flatpickr-input {
  @apply block w-full;
}

.flatpickr-mobile {
  min-height: 3.25rem;
}

.flatpickr-calendar {
  @apply rounded-none pt-6;

  &.hasTime {
    @apply pt-0;
    width: 147px;
  }

  &.arrowTop {
    @apply mt-1;
  }

  &.arrowBottom {
    @apply -mt-10;
  }

  & .flatpickr-weekdaycontainer {
    @apply px-8;
  }

  & .dayContainer {
    @apply px-8 pb-5;
  }

  & .flatpickr-day {
    height: 35px;
    line-height: 33px;
    border: none;

    &.today {
      font-weight: bold;
    }
  }

  & .flatpickr-current-month {
    font-size: 160%;
  }

  & .flatpickr-months .flatpickr-month {
    height: 35px;
  }

  & .flatpickr-months .flatpickr-prev-month,
  & .flatpickr-months .flatpickr-next-month {
    top: 12px;
    & svg {
      width: 27px;
      height: 27px;
    }
  }

  & .flatpickr-months .flatpickr-month {
    margin-bottom: 0.5rem;
  }
}
