@import 'style/fonts';
@import 'tailwindcss/base';
@import 'tailwindcss/components';

@import 'style/components/call-ribbon';
@import 'style/components/button';
@import 'style/components/rte';
@import 'style/components/scroll-hint';
@import 'style/components/headline';
@import 'style/components/faq';
@import 'style/components/usp';
@import 'style/components/footer';
@import 'style/components/mobile-nav';

@import 'tailwindcss/utilities';

@import 'style/components/form';
@import 'style/utilities/transitions';
@import 'style/components/inquiry-form';

@import 'style/utilities/effects';
@import 'style/utilities/icons';

sup {
  top: -0.8em;
}

.freeze body {
  overflow: hidden;
  height: 100vh;
}
