.rte {
  &.rte--light {
    @apply text-white;
  }

  & p {
    @apply font-light mb-6 inline-block w-full;
  }

  & p:last-child {
    @apply mb-0;
  }

  & a {
    @apply underline font-bold;
  }

  & strong {
    @apply font-bold;
  }

  & h1,
  & h2,
  & h3,
  & h4 {
     @apply text-xl font-bold mb-4;
  }

  & ul {
    @apply list-disc pl-8 mb-4;

    & li {
      @apply ml-2;
    }
  }
}
