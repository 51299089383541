.usp-item {
  @apply mb-5 pr-16 pl-8 relative;
}

.usp-item__check {
  @apply block rounded-full border-brand-a border-2 flex-none absolute mt-1;
  background: theme('colors.brand.a');
  width: 1.5rem;
  height: 1.5rem;

  top: 0;
  left: 0;

  &:after {
    @apply block absolute w-full h-full;
    content: '';
    background-image: url('~@/assets/svg/icons/checkmark-white.svg');
    background-size: 75%;
    background-repeat: no-repeat;
    background-position: center;
  }
}

@screen lg {
  .usp-item {
    @apply p-2 mb-0 mx-4 rounded-full bg-gray-100 shadow-2xl flex-grow;
    flex: 0 1 23rem;
    min-width: 0%;

    transition: background-color 0.2s ease, box-shadow 1s ease;

    &:hover {
      @apply shadow-xl bg-gray-200;

      & .usp-item__check:after {
        transform: rotate(6deg) scale(1.2);
      }
    }
  }

  .usp-item__wrapper {
    @apply relative aspect-w-1 aspect-h-1 h-0 rounded-full border-brand-a border-2 flex flex-col items-center;
  }

  .usp-item__content {
    @apply absolute inset-0 flex flex-col justify-center px-6 rounded-full -mt-5;
    margin-top: 10%;

    & img {
      transition: transform .3s ease;
    }

    &:hover {
      & img {
        transform: scale(1.25);
      }
    }

    & p {
      @apply flex-1;
      min-height: 4rem;
    }
  }

  .usp-item__check {
    @apply mt-4;
    background: radial-gradient(theme('colors.brand.a') 0%, theme('colors.brand.a') 54%, transparent 56%);
    width: 4.5rem;
    height: 4.5rem;
    top: auto;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%) translateY(100%) translateY(1rem);

    &:before {
      @apply block h-4 bg-brand-a absolute -mt-4;
      content: '';
      width: 2px;
      left: calc(50% - 1px);
    }

    &:after {
      background-size: 40%;
      transition: all 0.2s ease;
    }
  }
}
