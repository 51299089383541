.scroll-hint {
  @apply w-10 h-10 block mx-auto relative;
  background-image: url(~@/assets/svg/icons/mouse.svg);
  background-repeat: no-repeat;
  animation: boogie 1.3s infinite ease;

  &:after {
    @apply block w-full h-4 absolute;
    content: '';
    top: 100%;
    left: 0;
    background-image: url(~@/assets/svg/icons/arrow-scroll.svg);
    background-repeat: no-repeat;
    background-size: 12px;
    background-position: 50% 50%;
  }
}

@keyframes boogie {
  0% {
    transform: translate(0, 0);
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  20% {
    opacity: 1;
  }
  100% {
    transform: translate(0, 12px);
    opacity: 0;
  }
}
