@font-face {
    font-family: 'KiaSignature';
    src: url('~@/assets/fonts/KiaSignatureRegular.eot'); /* IE9 Compat Modes */
    src: url('~@/assets/fonts/KiaSignatureRegular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('~@/assets/fonts/KiaSignatureRegular.woff') format('woff'), /* Modern Browsers */
         url('~@/assets/fonts/KiaSignatureRegular.woff2') format('woff2'); /* Modern Browsers */
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'KiaSignature';
    src: url('~@/assets/fonts/KiaSignatureLight.eot'); /* IE9 Compat Modes */
    src: url('~@/assets/fonts/KiaSignatureLight.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('~@/assets/fonts/KiaSignatureLight.woff') format('woff'), /* Modern Browsers */
         url('~@/assets/fonts/KiaSignatureLight.woff2') format('woff2'); /* Modern Browsers */
    font-style: normal;
    font-weight: 300;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'KiaSignature';
    src: url('~@/assets/fonts/KiaSignatureBold.eot'); /* IE9 Compat Modes */
    src: url('~@/assets/fonts/KiaSignatureBold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('~@/assets/fonts/KiaSignatureBold.woff') format('woff'), /* Modern Browsers */
         url('~@/assets/fonts/KiaSignatureBold.woff2') format('woff2'); /* Modern Browsers */
    font-style: normal;
    font-weight: 700;
    text-rendering: optimizeLegibility;
}
