@layer components {
  .headline {
    @apply text-4xl text-brand-black font-bold leading-tight relative mb-16;
  }

  .headline--pattern:before {
    /* dot grid pattern */
    @apply block w-32 h-48 absolute;
    content: '';
    opacity: 0.05;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAAH0lEQVQYlWNgoCYwZmBgSIPSWEEaAwPDfyhNngk0AgD5tgNke3is6QAAAABJRU5ErkJggg==');
    background-size: 13px;
    top: -4.8rem;
  }

  .headline--underline {
    @apply pb-6 pt-5 mb-12 sm:mb-20;
  }

  .headline--underline:after {
    /* underline */
    @apply block absolute w-12 bottom-0 bg-brand-a;
    content: '';
    height: 2px;
  }

  .headline--sm {
    @apply text-3xl mb-16;
  }

  .headline--sm:before {
    top: -5rem;
  }

  .headline--center {
    @apply text-center;
  }

  .headline--center:before,
  .headline--center:after {
    left: 50%;
    transform: translate(-50%, 0);
  }

  .headline--underline {
    @apply mb-20;
  }
}
